<template>
  <svg
    :width="`${width}px`"
    :height="`${height}px`"
    viewBox="0 0 122 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 57.1 (83088) - https://sketch.com -->
    <title>Group 23</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Flight"
      stroke="none"
      stroke-width="1"
      :fill="fill"
      fill-rule="evenodd"
    >
      <g
        id="Flight_Search_result_Multi-City"
        transform="translate(-165.000000, -18.000000)"
        fill="#1C3C6B"
      >
        <g
          id="Group-23"
          transform="translate(165.000000, 18.000000)"
        >
          <polygon
            id="Fill-1"
            points="4.52903244 27.9290334 19.6258072 27.9290334 19.6258072 24.9096784 7.22681569 24.9096784"
          />
          <polygon
            id="Fill-2"
            points="16.6064523 9.81290362 3.59433541 9.81290362 0.75483874 12.8322586 16.6064523 12.8322586"
          />
          <g id="Group-22">
            <g
              id="Group-5"
              transform="translate(0.000000, 5.066197)"
            >
              <path
                id="Fill-3"
                d="M28.9403344,0.168909738 C28.9393732,0.167403572 28.9382746,0.166034329 28.937176,0.164391238 C28.9366267,0.163706617 28.9360775,0.162885072 28.9355282,0.162200451 C28.934155,0.160420435 28.9326444,0.15864042 28.9309966,0.156723481 C28.9307219,0.156312708 28.9304473,0.15603886 28.9301727,0.155628087 C28.8142738,0.0232223505 28.0866101,-0.291977242 23.9424013,3.42784349 L17.0770406,1.75750473 L16.6411839,2.19128071 L20.5074551,4.1612097 L19.8603988,4.77093333 C19.8603988,4.77093333 19.8363677,5.0052107 20.0368562,4.9815228 L20.7367811,4.47846316 L21.1613775,4.74724543 L9.40354612,14.0186593 L4.66858259,18.46144 L7.85140747,16.1019616 L1.11746293,22.0928077 L6.95854668,17.4868133 L5.62722011,18.6662787 L7.46100402,17.1870862 L-5.49283783e-05,23.8248993 L6.65287026,18.5786472 L2.15547196,22.5853242 L5.67871547,19.8071314 L1.79967339,23.4227528 L7.00482384,19.318175 L7.00413724,19.3195442 L22.9039803,6.78180266 L22.9089238,6.7882381 L23.1333063,7.36468914 L22.507672,7.95716031 C22.4454656,8.147485 22.6778127,8.16418976 22.6778127,8.16418976 L23.3966878,7.64113917 L24.554578,11.7247676 L25.061155,11.3765693 L24.7807456,4.43711204 C29.129288,1.1132762 29.0452476,0.323223353 28.9403344,0.168909738"
              />
            </g>
            <path
              id="Fill-6"
              d="M31.7558944,20.34879 L34.2543117,12.2352075 L36.7528663,20.34879 L31.7558944,20.34879 Z M32.8025547,9.66869957 L27.2653622,26.8902085 L29.4936692,27.6597227 L31.1481119,22.4026536 L37.3944298,22.4026536 L39.0150916,27.6854645 L41.2435359,26.8578944 L35.7063434,9.66869957 L32.8025547,9.66869957 Z"
            />
            <polygon
              id="Fill-8"
              points="54.5124744 18.3571038 49.8193938 9.33448121 47.7102813 10.4456214 53.400724 20.5454269 53.400724 27.3319398 55.4605382 27.3319398 55.4605382 20.5454269 61.2497146 10.4456214 59.1642214 9.33448121"
            />
            <path
              id="Fill-10"
              d="M72.2722061,20.34879 L74.7706233,12.2352075 L77.269178,20.34879 L72.2722061,20.34879 Z M73.3188663,9.66869957 L67.7816738,26.8902085 L70.0099808,27.6597227 L71.6644235,22.4026536 L77.9107414,22.4026536 L79.5314032,27.6854645 L81.7598475,26.8578944 L76.2225177,9.66869957 L73.3188663,9.66869957 Z"
            />
            <path
              id="Fill-12"
              d="M92.5303962,20.34879 L95.0288135,12.2352075 L97.5273681,20.34879 L92.5303962,20.34879 Z M93.5770565,9.66869957 L88.039864,26.8902085 L90.2681709,27.6597227 L91.9226137,22.4026536 L98.1689316,22.4026536 L99.7895934,27.6854645 L102.018038,26.8578944 L96.4807079,9.66869957 L93.5770565,9.66869957 Z"
            />
            <polygon
              id="Fill-14"
              points="118.902856 9.66869957 118.902856 19.9395235 119.103894 24.4843127 119.035646 24.4999221 112.214502 9.66869957 109.29039 9.66869957 109.29039 27.3319261 111.624846 27.3319261 111.624846 17.178857 111.447016 12.6001106 111.526799 12.5426024 118.359477 27.3319261 121.099992 27.3319261 121.099992 9.66869957"
            />
            <g
              id="Group-18"
              transform="translate(4.119628, 0.000000)"
            >
              <path
                id="Fill-16"
                d="M2.51758729,8.20291193 C3.18482977,8.20291193 3.69058281,7.92974808 4.37718754,7.51390917 L4.37718754,3.91786795 L2.04273146,3.91786795 L2.04273146,4.73941337 L3.41594092,4.73941337 L3.41594092,7.08615786 C3.27861998,7.31194592 2.78165547,7.45434713 2.44810289,7.45434713 C1.66166584,7.45434713 0.897749416,6.89597009 0.897749416,4.17542244 C0.897749416,3.2368068 1.03410911,0.813110886 2.35664714,0.813110886 C2.98818617,0.813110886 3.41374379,1.25277461 3.699646,1.76363894 L4.40698619,1.33602455 C3.96618595,0.611284561 3.31295021,0.0646830093 2.41940282,0.0646830093 C0.441431913,0.0646830093 0.103347744,2.66651735 0.103347744,4.15159762 C0.103347744,5.73170331 0.468209497,8.20291193 2.51758729,8.20291193"
              />
            </g>
            <g
              id="Group-21"
              transform="translate(11.260318, 0.000000)"
            >
              <path
                id="Fill-19"
                d="M2.26333756,0.813151963 C3.66936673,0.813151963 3.63366328,2.6308212 3.63366328,4.12781388 C3.63366328,5.50595632 3.64547288,7.45438821 2.26333756,7.45438821 C1.14334793,7.45438821 0.893149164,6.26643353 0.893149164,4.12781388 C0.893149164,2.9635471 0.857445718,0.813151963 2.26333756,0.813151963 M2.26333756,8.20295301 C4.50331683,8.20295301 4.46764746,5.12585464 4.46764746,4.12781388 C4.46764746,3.08226041 4.50331683,0.0647240866 2.26333756,0.0647240866 C0.142415553,0.0647240866 0.0590617388,2.82100897 0.0590617388,4.12781388 C0.0590617388,5.14967946 -0.000398230743,8.20295301 2.26333756,8.20295301"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GoZayaanBrandLogo',
  props: {
    width: {
      type: String,
      default: '150',
    },
    height: {
      type: String,
      default: '50',
    },
    fill: {
      type: String,
      default: '#1C3C6B',
    },
  },
}
</script>
